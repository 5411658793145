import React from "react";
import { useStaticQuery, graphql } from 'gatsby'

const MainLogo = () => {
    const data = useStaticQuery(graphql`
    query logo {
        wp {
        themeGeneralSettings {
            SiteOptions {
            logo {
                localFile {
                childImageSharp {
                    fluid(maxWidth: 170, quality: 100) {
                    src
                    }
                }
                }
            }
            }
        }
        }
    }
    `)
    const logo = data.wp.themeGeneralSettings.SiteOptions.logo.localFile.childImageSharp.fluid.src
    return (
        <img src={logo} alt="Logo" />
    )
};

export default MainLogo
