import React from "react";
import css from "./MailList.module.css"

// a basic form
const SubscribeForm = ({ parentType, status, message, className, style, onSubmitted }) => {
  let input;
  const submit = () => {
    const obj = {
      Authoritative : "group[21733][1]",
      Neglectful : "group[21733][2]",
      Authoritarian : "group[21733][3]",
      Indulgent : "group[21733][4]",
    };
    const pType = obj[parentType];
    const sendThis = {
      EMAIL: input.value,
      [pType]: 1,
    }
    input &&
    input.value.indexOf("@") > -1 &&
    onSubmitted(sendThis);
  }

  return (
    <div className={` ${css.box} ${css[className]} `} style={style}>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input
        ref={node => (input = node)}
        type="email"
        placeholder="Your email"
        className={css.email}
      />
      <button className={css.submit} onClick={submit}>Subscribe</button>
    </div>
  );
};

export default SubscribeForm;
