import React from "react";
import style from "./Footer.module.css";
import SocialMenu from './socialMenu'
import MailList from "../mailList/MailList"
import Logo from '../Logo'

const Footer = () => (
    <footer>
      <div className={style.top}>
        <header>
          <h1>subscribe to our monthly newsletter</h1>
          <h3>
            Receive monthly newsletters on our latest articles, tips and advice on
            parenting
        </h3>
        </header>
        <section>
          <MailList />
        </section>
      </div>
      <div className={style.middle}>
        <Logo/>
        <SocialMenu className={style.socialIcons} />
      </div>
      <div className={style.bottom}>
        <p>&copy; 2020 Involved Fathers. All Rights Reserved</p>
      </div>
    </footer>
  )

export default Footer