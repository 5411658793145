import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default ({ className }) => {
    const { wpMenu } = useStaticQuery(graphql`
    {
        wpMenu(name: {eq: "Social"}) {
            name
            menuItems {
              nodes {
                parentId
                label
                order
                path
                target
              }
            }
          }
    }
  `)

    return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
        <div className={className}>
            {wpMenu.menuItems.nodes.map((menuItem, i) => {
                if (menuItem.parentId) {
                    return null
                }
                return (
                    <a key={i + menuItem.path} href={menuItem.path} target={menuItem.target}><i aria-label={menuItem.label} className={`fa fa-${menuItem.label}`} /></a>
                )
            })}
        </div>
    ) : null
}