import PropTypes from "prop-types"
import React from "react"
import Menu from './menu'
import './header.css';

const Header = ({ siteTitle }) => (
  <header className="header">
    <input type="checkbox" id="nav-toggle" />
    <label htmlFor="nav-toggle" className="burger-menu">
      <span className="navbar-toggle">
        <i className="fa fa-bars" />
      </span>
    </label>
    <Menu />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
