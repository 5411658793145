import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

export default () => {
    function openMenu(event) {
        let t = event.target;
        let x = t.parentNode.childNodes[1];
        if (x.style.display === "block") {
            x.style.display = "none";
        } else {
          x.style.display = "block";
        }
      }

    const { wpMenu } = useStaticQuery(graphql`
    {
    wpMenu(name: {eq: "Primary Menu"}) {
        name
        menuItems {
            nodes {
            label
            order
            path
            target
            childItems {
                nodes {
                    label
                    path
                    target
                    order
                }
            }
            parentId
            }
        }
        }
    }
  `)

    return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
        <nav className="navbar">
            <ul className="nav-links">
                {wpMenu.menuItems.nodes.map((menuItem, i) => {
                    if (menuItem.parentId) {
                        return null
                    }
                    if (menuItem.childItems.nodes.length > 0) {
                        return (
                            <div key={i + menuItem.path} onTouchStart={openMenu} className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">{menuItem.label}</a>
                                <div className="dropdown-menu">
                                {menuItem.childItems.nodes.map((childItem, i) => (
                                    <Link key={i + menuItem.path} className="dropdown-item" to={childItem.path} target={childItem.target} dangerouslySetInnerHTML={{ __html: childItem.label }}></Link>
                                ))}
                                    {/* // <a href="#" >Action</a>
                                    // <a href="#" className="dropdown-item">Another action</a> */}
                                </div>
                            </div>
                        )
                    }
                    return (
                        <li key={i + menuItem.path} className="nav-item">
                            <Link to={menuItem.path} target={menuItem.target} dangerouslySetInnerHTML={{ __html: menuItem.label }}></Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    ) : null
}