import React from "react"
import styles from "./MailList.module.css"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import SubscribeForm from "./SubscribeForm";
 
const url = "https://gmail.us4.list-manage.com/subscribe/post?u=ea1e6ffd1917f85c48ffd329f&amp;id=560f5c5c7b";

const MailList = ({ className, parentType, addToListComplete }) => (
  <div className={styles.emailListRequest}>
    <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => {
      if (status=== "sending"){
        if(addToListComplete){
          addToListComplete()
        }
      }
      return (
      <div>
        <SubscribeForm parentType={parentType} className={className} onSubmitted={formData => {
          subscribe(formData)
          } } />
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
        {status === "success" && <div style={{ color: "green" }}>Subscribed !</div>}
      </div>
    )}}
  />
  </div>
)

export default MailList
